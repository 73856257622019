<template>
    <v-card :class="`mx-auto align-self-stretch ` + ($vuetify.breakpoint.mdAndUp ? `pa-4 ` : `pa-0`)
        " :flat="$vuetify.breakpoint.mdAndUp ? true : false" :elevation="$vuetify.breakpoint.mdAndUp ? `1` : `0`"
        width="800">
        <v-card-title class="px-0 mb-4">
            <h1>Інформація про продаж SN</h1>
        </v-card-title>
        <p>
            Тут можна перевірити товари, які були відвантажені через Юг-Контракт.
        </p>
        <v-form @submit.prevent="check">
            <v-text-field v-model="sn" label="Уведіть SN* або IMEI** виробу"
                hint="для пошука необхідно вводити не менш ніж 4 символи" clearable></v-text-field>
            <v-btn :disabled="!checkForm" @click="check" color="primary" class="mt-2">
                перевірити
            </v-btn>
        </v-form>
        <p class="mt-4 body-2"><b>*</b> для пошуку треба вводити символи з початку серійного номера або IMEI.<br>
            Наприклад, щоб знайти 1234567QWERTY, достатньо ввести 12345. Але зауважте, що по частині номера з середини
            (наприклад, по QWERTY) пошук вестись не буде.<br>
        </p>

        <p class="body-2"><b>**</b> для деяких товарів виробник указує серійний номер та IMEI.<br>
            Такі товари рекомендуємо перевіряти по IMEI і потім, якщо товар не знайдено, виконати пошук по серійному
            номеру.<br>
            Наприклад, мобільні телефони перевіряються по IMEI.</p>
        <p class="text-center my-6">
            <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
        </p>
        <v-data-table v-if="snData.length" :headers="headers" :items="snData" class="elevation-1" hide-default-footer
            :footer-props="footerProps" mobile-breakpoint="0"> </v-data-table>
        <v-alert v-if="result === -1" border="left" colored-border type="error" elevation="2" color="primary"
            class="text--primary">
            Уведений серійний номер не знайдено!<br>
            Продаж товара з таким серійним номером був проведений без участі Юг-Контракт.
        </v-alert>
    </v-card>
</template>

<script>
import {
    mapActions
} from 'vuex'

export default {
    data: () => ({
        sn: '',
        result: 0,
        loading: false,
        headers: [{
            text: 'Код',
            value: 'g_id'
        },
        {
            text: 'Назва',
            value: 'name'
        },
        {
            text: 'Серійний номер / IMEI',
            value: 'sn'
        },
        ],
        footerProps: {
            'items-per-page-options': [20, 40, 80, 100],
            'items-per-page-text': 'на стор.'
        },
    }),
    computed: {
        checkForm() {
            return this.sn && this.sn.length > 3 // Кнопка активна, якщо є введений серійний номер
        },
        snData() {
            return this.$store.state.catalog.snData
        },
    },
    methods: {
        ...mapActions(['checkSN']),
        check() {
            if (this.checkForm) {
                this.result = 0
                this.loading = true
                this.checkSN(this.sn).then((result) => {
                    this.loading = false
                    this.result = result.length > 0 ? result.length : -1
                })
                    .catch((error) => {
                        this.loading = false
                        this.result = 0
                    })
            }
        }
    }
}
</script>
