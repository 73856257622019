<template>
    <v-container fluid fill-height>
        <HeaderLight />
        <CheckSN />
    </v-container>
</template>

<script>
import CheckSN from '../components/CheckSN.vue'
import HeaderLight from '../components/HeaderLight.vue'
export default {
    components: {
        CheckSN,
        HeaderLight
    }
}
</script>
